html body {
    margin: 0;
}

:root {
    --primary-color: #ff8c42;
    --primary-alt1: #fcaf58;
    --primary-alt2: #f9c784;
    --white: #fff;
    --secondary-color: #4e598c;
    --gray: #ebebeb;
    --dark-gray: #666;
}

:root {
    --animate-duration: 800ms;
    --animate-delay: 0.9s;
}

#c {
    width: 100%;
    height: 100%;
}

.header-container-standard {
    display: grid;
    justify-items: center;
}

@tailwind base;

@tailwind components;

@tailwind utilities;